import React from "react";
import ContactModal from "../components/Contact";
import Breadcrumb from "../layout/Breadcrumb";
import { Link } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import classes from "../components/Contact.module.css";
import Helmet from "../helmet/helmet";
const Contact = () => {
  return (
    <>
    <Helmet title={"KAPETAN :: KONTAKT | IZRADA SAJTOVA"}></Helmet>
      <Breadcrumb
        title={"Kontakt"}
        breadcrumb={
          <div className={classes.breadcrumbHeader}>
            <Link to={"/"}>Početna </Link>
            <MdKeyboardDoubleArrowRight /> <Link to={"/contact"}> Kontakt</Link>
          </div>
        }
      />
      <ContactModal />
    </>
  );
};

export default Contact;
