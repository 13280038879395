import React from "react";
import logo from "../images/logo-dark.png";
import "./Loader.css";
const Loader = () => {
  return (
    <div className="loader-container">
   <img src={logo} alt={"KAPETAN LOZNICA :: IZRADA SAJTOVA, FLAJERA I REKLAMNOG MATERIJALA"}/>
    </div>
  );
};

export default Loader;
