import logo from "../images/logo-vertical-white.png";
import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div class="footer">
      <div className="footer-container">
        <img src={logo}></img>
        <div className="row">
          <h3>Usluge</h3>
          <ul>
            <li>Grafički Dizajn</li>
            <li>Izrada sajtova i SEO</li>
            <li>Vođenje instagrama</li>
            <li>Optimizacija sajta</li>
            <li>Održavanje & redizajn sajtova</li>
            <li>Online Prodavnice</li>
          </ul>
        </div>
        <div className="row">
          <h3>Korisni linkovi</h3>
          <ul>
            <li>
              <Link to={"/"}>Početna</Link>
            </li>
            <li>
              <Link to={"/onama"}>O Nama</Link>
            </li>
            <li>
              <Link to={"/usluge"}>Usluge</Link>
            </li>
            <li>
              <Link to={"/portfolio"}>Portfolio</Link>
            </li>
            <li>
              <Link to={"/contcontactact"}>Kontakt</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
