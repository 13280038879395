import React from "react";
import "./Button.css";
const Button = ({ className, props, children }) => {
  return (
    <button className={className} {...props}>
      {children}
    </button>
  );
};

export default Button;
