import React, { useEffect } from "react";
import Breadcrumb from "../layout/Breadcrumb";
import { useState } from "react";
import data from "../data";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import classes from "../components/PortfolioHome.module.css";
import PortfolioHome from "../components/PortfolioHome";
import Helmet from "../helmet/helmet";
const Portfolio = () => {
  return (
    <>
    <Helmet title={"KAPETAN :: PORTFOLIO | LOZNICA"}></Helmet>
      <Breadcrumb
        title={"Portfolio"}
        breadcrumb={
          <div className={classes.breadcrumbHeader}>
            <Link to={"/"}>Početna </Link>
            <MdKeyboardDoubleArrowRight />{" "}
            <Link to={"/portfolio"}> Portfolio</Link>
          </div>
        }
      />
      <PortfolioHome slice={false} />
    </>
  );
};

export default Portfolio;
