import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
const firebaseConfig = {
  apiKey: "AIzaSyC27a-_yQxDbmijAqez_fdtX0ob6l9bNsU",
  authDomain: "nkreative-a29b1.firebaseapp.com",
  projectId: "nkreative-a29b1",
  databaseURL:
    "https://nkreative-a29b1-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "nkreative-a29b1.appspot.com",
  messagingSenderId: "364792681287",
  appId: "1:364792681287:web:fbccd1815de8a8655c3262",
  measurementId: "G-8H3S5NPF2W",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
export default database;
