import React from "react";
import { NavLink, Link } from "react-router-dom";
import classes from "./MainNavigation.module.css";
import Sidebar from "./layout/Sidebar";
import logo from "./images/logo-dark.png";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
const MainNavigation = () => {
  const [sidebar, setSidebar] = useState();
  const sidebarHandler = () => {
    setSidebar((prev) => !prev);
  };
  return (
    <>
      <main className={classes.mainNavigation}>
        <Link to={"/"}>
          <img src={logo} alt="KAPETAN" className={classes.logo} />
        </Link>
        <ul>
          <li>
            <NavLink
              to={"/"}
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              Početna
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
              to={"/onama"}
            >
              O Nama
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
              to={"/usluge"}
            >
              Usluge
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
              to={"/portfolio"}
            >
              Portfolio
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
              to={"/contact"}
            >
              Kontakt
            </NavLink>
          </li>
        </ul>
        <div>
          <GiHamburgerMenu className={classes.menu} onClick={sidebarHandler} />
        </div>
      </main>
      <Sidebar sidebarHandler={sidebarHandler} sidebar={sidebar} />
    </>
  );
};

export default MainNavigation;
