import about_1 from "../images/ab-inner-1.jpg";
import about_2 from "../images/ab-inner-2.jpg";
import about_3 from "../images/ab-inner-3-1.jpg";
import React from "react";
import Breadcrumb from "../layout/Breadcrumb";
import classes from "./About.module.css";
import { IoMdCheckmark } from "react-icons/io";
import { Link } from "react-router-dom";
import Helmet from "../helmet/helmet";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
const About = () => {
  return (
    <>
    
    <Helmet title={"KAPETAN :: O NAMA | IZRADA SAJTOVA"}></Helmet>
    <div>
      <Breadcrumb
        title={"O Nama"}
        breadcrumb={
          <div className={classes.breadcrumbHeader}>
            <Link to={"/"}>Početna </Link>
            <MdKeyboardDoubleArrowRight /> <Link to={"/onama"}> O Nama</Link>
          </div>
        }
      />
      <div
        className={classes["tp-about-us-area"]}
        data-aos-duration="1500"
        data-aos-delay="100"
        data-aos="fade-up"
      >
        <div className={classes["tp-about-text"]}>
          <h2 className={classes["ab-title-xs"]}>
            KAPETAN se bavi dizajniranjem, kreiranjem i brendingom!
          </h2>
          <p className={classes.aboutusText}>
            KAPETAN je mala digitalna agencija koja se bavi grafičkim i web
            dizajnom, naš pristup nije mali - mi težimo ka jedinstvenim
            rešenjima koja će vas razlikovati od konkurencije.<br></br>
            <br></br>
            Naš pristup je jednostavan - slušamo vas, razumemo vaše potrebe i
            težimo da vam pružimo personalizovano rešenje koje će premašiti vaša
            očekivanja. Naš fokus je na vašem zadovoljstvu, zato radimo zajedno
            sa vama kako bi postigli konačni proizvod koji vam donosi uspeh.
            <br></br>
            Budite jedinstveni, budite prepoznatljivi, budite uspešni - to je
            ono što mi težimo. Hajde da zajedno stvorimo brend koji će ostati
            zapamćen!
          </p>
          <div className={classes["tp-inner-list"]}>
            <ul>
              <li>
                <IoMdCheckmark />
                WEB STRANICE
              </li>
              <li>
                <IoMdCheckmark />
                BRENDING
              </li>
              <li>
                <IoMdCheckmark />
                GRAFIČKI DIZAJN
              </li>
              <li>
                <IoMdCheckmark />
                INSTAGRAM CONTENT MANAGEMENT
              </li>
              <li>
                <IoMdCheckmark />
                ODRŽAVANJE & REDIZAJN SAJTOVA
              </li>
              <li>
                <IoMdCheckmark />
                IZRADA ONLINE PRODAVNICA
              </li>
            </ul>
          </div>
        </div>
        <div className={classes.aboutImages}>
          <img src={about_1} className={classes.about_1} />
        </div>
      </div>
    </div>
    </>
  );
};

export default About;
