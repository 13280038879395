import Contact from "../components/Contact";
import React from "react";
import Cards from "../components/Cards";
import Breadcrumb from "../layout/Breadcrumb";
import AllServices from "../components/AllServices";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";
import Helmet from "../helmet/helmet";
import classes from "./Services.module.css";
const Services = () => {
  return (
    <>
        <Helmet title={"KAPETAN :: DIZAJN REKLAMNOG MATERIJALA, IZRADA I ODRŽAVANJE SAJTOVA | LOZNICA"}></Helmet>
      <Breadcrumb
        title={"Usluge"}
        breadcrumb={
          <div className={classes.breadcrumbHeader}>
            <Link to={"/"}>Početna </Link>
            <MdKeyboardDoubleArrowRight /> <Link to={"/usluge"}> Usluge</Link>
          </div>
        }
      />
      <main
        className={classes.servicesContainerMain}
        data-aos-duration="1000"
        data-aos-delay="200"
        data-aos="fade-up"
      >
        <h4
          className={
            classes["tp-section-subtitle"] + " " + classes["tp-yellow-color"]
          }
        >
          Naše usluge
        </h4>
        <h2 className={classes["tp-section-title"]}>Šta mi pružamo?</h2>
        <div
          className={classes.servicePageCardsContainer}
          data-aos-duration="1000"
          data-aos-delay="200"
          data-aos="fade-up"
        >
          <div className={classes.servicesCards}>
            <AllServices cardMinWidth={"cardMinWidth"} />
            <Cards
              title={"Optimizacija sajtova"}
              description={
                "Kontinuirano optimizujemo vaš veb sajt kako bi bio brži, sigurniji i bolje rangiran na pretraživačima, pružajući bolje korisničko iskustvo."
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 55 55"
                viewBox="0 0 55 55"
                id="optimization"
              >
                <path
                  d="M52.06,31.16l-2.28-1.55v-4.22l2.28-1.55c0.37-0.25,0.53-0.72,0.39-1.14l-1.92-5.85c-0.14-0.42-0.52-0.7-0.98-0.69
  l-2.78,0.08l-2.51-3.43l0.93-2.58c0.15-0.42,0.01-0.89-0.36-1.15l-5.02-3.61c-0.35-0.26-0.84-0.25-1.19,0.01l-2.21,1.67l-4.07-1.31
  l-0.79-2.64C31.43,2.79,31.04,2.5,30.6,2.5h-6.2c-0.44,0-0.83,0.29-0.96,0.71l-0.79,2.64l-4.07,1.31l-2.21-1.67
  c-0.35-0.26-0.83-0.27-1.19-0.01l-5.02,3.61C9.8,9.34,9.66,9.81,9.81,10.23l0.93,2.58l-2.51,3.43l-2.78-0.08c-0.01,0-0.02,0-0.03,0
  c-0.43,0-0.81,0.28-0.95,0.69L2.55,22.7c-0.14,0.42,0.02,0.89,0.39,1.14l2.28,1.55v4.22l-2.28,1.55c-0.37,0.25-0.53,0.72-0.39,1.14
  l1.92,5.85c0.14,0.41,0.52,0.69,0.95,0.69c0.01,0,0.02,0,0.03,0l2.78-0.08l2.51,3.43l-0.93,2.58c-0.15,0.42-0.01,0.89,0.36,1.15
  l5.02,3.61c0.36,0.26,0.84,0.25,1.19-0.02l2.21-1.67l4.07,1.31l0.79,2.64c0.13,0.42,0.52,0.71,0.96,0.71h6.2
  c0.44,0,0.83-0.29,0.96-0.71l0.79-2.64l4.07-1.31l2.21,1.67c0.35,0.27,0.83,0.27,1.19,0.02l5.02-3.61
  c0.36-0.26,0.51-0.73,0.36-1.15l-0.93-2.58l2.51-3.43l2.78,0.08c0.46-0.03,0.84-0.27,0.98-0.69l1.92-5.85
  C52.59,31.88,52.43,31.41,52.06,31.16z M48.86,36.82l-2.56-0.07c-0.33-0.01-0.64,0.14-0.83,0.41l-3.14,4.28
  c-0.2,0.27-0.25,0.62-0.13,0.93l0.85,2.37l-3.81,2.74l-2.03-1.54c-0.26-0.2-0.6-0.25-0.91-0.16l-5.07,1.63
  c-0.31,0.1-0.56,0.35-0.65,0.67l-0.73,2.43h-4.71l-0.73-2.43c-0.09-0.32-0.34-0.56-0.65-0.67l-5.07-1.63
  c-0.31-0.1-0.65-0.04-0.91,0.16l-2.03,1.54l-3.81-2.74l0.85-2.37c0.11-0.31,0.06-0.66-0.13-0.93l-3.14-4.28
  c-0.19-0.26-0.5-0.41-0.83-0.41l-2.55,0.07l-1.45-4.43l2.1-1.43c0.27-0.19,0.44-0.5,0.44-0.83v-5.28c0-0.33-0.16-0.64-0.44-0.83
  l-2.1-1.43l1.45-4.43l2.55,0.07c0.32,0.01,0.64-0.14,0.83-0.41l3.14-4.28c0.2-0.27,0.25-0.62,0.13-0.93l-0.85-2.37l3.81-2.74
  l2.03,1.54c0.26,0.2,0.6,0.25,0.91,0.16l5.07-1.63c0.31-0.1,0.56-0.35,0.65-0.67l0.73-2.43h4.71l0.73,2.43
  c0.09,0.32,0.34,0.57,0.65,0.67l5.07,1.63c0.31,0.1,0.65,0.04,0.91-0.16l2.03-1.54l3.81,2.74l-0.85,2.37
  c-0.11,0.31-0.06,0.66,0.13,0.93l3.14,4.28c0.19,0.26,0.51,0.42,0.83,0.41l2.56-0.07l1.45,4.43l-2.1,1.43
  c-0.27,0.19-0.44,0.5-0.44,0.83v5.28c0,0.33,0.16,0.64,0.44,0.83l2.1,1.43L48.86,36.82z"
                ></path>
                <path
                  d="M27.5,12.66c-8.18,0-14.84,6.66-14.84,14.84s6.66,14.84,14.84,14.84s14.84-6.66,14.84-14.84S35.68,12.66,27.5,12.66z
   M35.09,37.83l-3.95-3.95c0.55-0.33,1.08-0.72,1.55-1.2c0.47-0.47,0.87-0.99,1.2-1.55l3.95,3.94
  C37.06,36.14,36.14,37.06,35.09,37.83z M28.96,23.34l-2.19-2.19c1.66-0.08,3.31,0.52,4.5,1.72c1.12,1.12,1.74,2.62,1.74,4.2
  s-0.62,3.08-1.74,4.2c-2.32,2.32-6.09,2.32-8.41,0c-1.2-1.2-1.8-2.85-1.72-4.5l2.19,2.19c0.21,0.21,0.51,0.32,0.82,0.29l3.78-0.43
  c0.46-0.05,0.83-0.42,0.88-0.88l0.43-3.78C29.29,23.86,29.18,23.56,28.96,23.34z M38.92,33.34l-4.18-4.18
  c0.18-0.67,0.28-1.37,0.28-2.09c0-2.12-0.83-4.12-2.33-5.62c-2.15-2.15-5.4-2.88-8.28-1.86c-0.32,0.12-0.57,0.39-0.64,0.72
  c-0.08,0.33,0.03,0.68,0.27,0.93l3.17,3.17l-0.28,2.51l-2.51,0.28l-3.17-3.17c-0.24-0.24-0.59-0.35-0.93-0.27
  c-0.33,0.08-0.61,0.32-0.72,0.64c-1.02,2.88-0.29,6.13,1.86,8.28c1.55,1.55,3.58,2.32,5.62,2.32c0.7,0,1.4-0.1,2.08-0.28l4.19,4.19
  c-1.76,0.9-3.74,1.42-5.84,1.42c-7.08,0-12.84-5.76-12.84-12.84s5.76-12.84,12.84-12.84s12.84,5.76,12.84,12.84
  C40.34,29.61,39.82,31.59,38.92,33.34z"
                ></path>
              </svg>
            </Cards>
            <Cards
              title={"Izrada online prodavnica"}
              description={
                "Kreiramo profesionalne online prodavnice koje omogućavaju laku navigaciju, sigurnu kupovinu i efikasno kontrolu nad proizvodima."
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                id="online-shop"
              >
                <g data-name="Layer 37">
                  <path d="M5,36c17.32,0,16.31.14,16.73-.32s.35.45-.73-14.75A1,1,0,0,0,20,20H17a4,4,0,0,0-8,0H6a1,1,0,0,0-1,.93c-1.09,15.3-1.15,14.3-.73,14.75A1,1,0,0,0,5,36Zm8-18a2,2,0,0,1,2,2H11A2,2,0,0,1,13,18ZM6.93,22H9v1a1,1,0,0,0,2,0V22h4v1a1,1,0,0,0,2,0V22h2.07l.86,12H6.07Z"></path>
                  <path d="M47,0H1A1,1,0,0,0,0,1V47a1,1,0,0,0,1,1H47a1,1,0,0,0,1-1V1A1,1,0,0,0,47,0ZM2,2H46V8H2ZM46,46H2V10H46Z"></path>
                  <path d="M5 4A1 1 0 0 0 5 6 1 1 0 0 0 5 4zM9 4A1 1 0 0 0 9 6 1 1 0 0 0 9 4zM13 4a1 1 0 0 0 0 2A1 1 0 0 0 13 4zM30 40h8a3 3 0 0 0 0-6H30a3 3 0 0 0 0 6zm0-4h8a1 1 0 0 1 0 2H30A1 1 0 0 1 30 36zM28 22H43a1 1 0 0 0 0-2H28A1 1 0 0 0 28 22zM28 26H40a1 1 0 0 0 0-2H28A1 1 0 0 0 28 26zM28 30h6a1 1 0 0 0 0-2H28A1 1 0 0 0 28 30z"></path>
                </g>
              </svg>
            </Cards>
          </div>
        </div>
        <Contact />
      </main>
    </>
  );
};

export default Services;
