import React from "react";
import classes from "./Home.module.css";
import Cards from "./Cards";
const AllServices = ({ cardMinWidth }) => {
  return (
    <>
      <Cards
        cardMinWidth={cardMinWidth}
        title={"Dizajn Brenda"}
        description={
          "Kreiramo originalne i prepoznatljive brendove koji odražavaju vašu jedinstvenu priču i privlače ciljanu publiku."
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          id="pencil-ruler"
        >
          <path d="M2.59375,18.2807c.00391.00568.0022.01361.00635.0191l3,4a.4995.4995,0,0,0,.7998,0l3-4c.00415-.00549.00244-.01342.00635-.0191a.48748.48748,0,0,0,.08636-.25854C9.493,18.01422,9.5,18.008,9.5,18V4A2.50294,2.50294,0,0,0,7,1.5H5A2.50294,2.50294,0,0,0,2.5,4V18c0,.008.007.01422.00739.02216A.48748.48748,0,0,0,2.59375,18.2807ZM6,21.1665,3.65094,18.03473a3.1838,3.1838,0,0,1,4.69812,0ZM3.5,4A1.50164,1.50164,0,0,1,5,2.5H7A1.50164,1.50164,0,0,1,8.5,4V5.5h-5Zm0,2.5h5V16.81775a4.23034,4.23034,0,0,0-5,0ZM15,22.5h4A2.50294,2.50294,0,0,0,21.5,20V4A2.50294,2.50294,0,0,0,19,1.5H15A2.50294,2.50294,0,0,0,12.5,4V20A2.50294,2.50294,0,0,0,15,22.5Zm-1.5-4H17a.5.5,0,0,0,0-1H13.5v-2H15a.5.5,0,0,0,0-1H13.5v-2H17a.5.5,0,0,0,0-1H13.5v-2H15a.5.5,0,0,0,0-1H13.5v-2H17a.5.5,0,0,0,0-1H13.5V4A1.50164,1.50164,0,0,1,15,2.5h4A1.50164,1.50164,0,0,1,20.5,4V20A1.50164,1.50164,0,0,1,19,21.5H15A1.50164,1.50164,0,0,1,13.5,20Z"></path>
        </svg>
      </Cards>
      <Cards
        cardMinWidth={cardMinWidth}
        title={"Grafički dizajn"}
        description={
          " Sa fokusom na kreativnost i estetiku, pružamo grafički dizajn koji komunicira vaše poruke na vizualno privlačan način."
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 64 64"
          id="graphic-design"
        >
          <path d="M61.503 27.609a1.008 1.008 0 0 1-.74.98c-5.318 1.474-10.645 2.927-15.97 4.4l-9.9-9.9.58-.84 10.58-15.22a.98.98 0 0 1 1.17-.37c9.172 3.392 14.436 12.811 14.28 20.95Z"></path>
          <path d="M40.553 37.229c-.16.705-1.118 5.612-1.29 6.44a.974.974 0 0 1-.74.78l-18.02 4.35a1.059 1.059 0 0 1-1.42-1.42l4.35-18.02a.974.974 0 0 1 .78-.74c.854-.175 5.704-1.13 6.44-1.29l9.9 9.9Z"></path>
          <path
            d="m35.603 32.279 4.95 4.95-.24 1.17-10.83-10.83 1.17-.24 4.95 4.95zM45.903 32.679c-.37.11-.74.21-1.11.31l-9.9-9.9.58-.84Z"
            opacity=".1"
          ></path>
          <path d="m40.554 37.225-9.9-9.9 4.243-4.242 9.9 9.9z"></path>
          <path
            fill="#fff"
            d="m29.953 39.349-9.45 9.45a1.059 1.059 0 0 1-1.42-1.42l9.45-9.45a1.004 1.004 0 0 1 1.42 1.42Z"
          ></path>
          <path d="M17.493 55.39C2.68 49.906 5.309 27.043 5.503 26.252a1 1 0 0 1 1.981.27c-.029.213-2.728 21.319 10.403 26.947a1.005 1.005 0 0 1-.394 1.92Z"></path>
          <path d="M15.493 55.389h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Z"></path>
          <path d="M8.493 54.389a3 3 0 0 1-6 0 3 3 0 0 1 6 0Z"></path>
          <path d="M48.493 55.389h-29a1 1 0 0 1 0-2h29a1 1 0 0 1 0 2Z"></path>
          <path d="M47.493 54.389a3 3 0 0 0 6 0 3 3 0 0 0-6 0Z"></path>
          <path d="M19.493 57.389c-5.982-.094-4.905.977-5-5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-11-28c-5.982-.094-4.905.977-5-5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1z"></path>
        </svg>
      </Cards>
      <Cards
        cardMinWidth={cardMinWidth}
        title={"Razvoj veb sajtova i aplikacija"}
        description={
          " Izrađujemo moderna i funkcionalna rešenja za veb sajtove i aplikacije, prilagođena vašim potrebama i ciljevima."
        }
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="coding">
          <path d="M28,1H4A3,3,0,0,0,1,4V28a3,3,0,0,0,3,3H28a3,3,0,0,0,3-3V4A3,3,0,0,0,28,1ZM4,3H28a1,1,0,0,1,1,1V7H3V4A1,1,0,0,1,4,3ZM28,29H4a1,1,0,0,1-1-1V9H29V28A1,1,0,0,1,28,29Z"></path>
          <path d="M25.5 6H26a1 1 0 000-2h-.5a1 1 0 000 2zM21.5 6H22a1 1 0 000-2h-.5a1 1 0 000 2zM10.71 14.29a1 1 0 00-1.42 0l-4 4a1 1 0 000 1.42l4 4a1 1 0 001.42 0 1 1 0 000-1.42L7.41 19l3.3-3.29A1 1 0 0010.71 14.29zM22.71 14.29a1 1 0 00-1.42 1.42L24.59 19l-3.3 3.29a1 1 0 000 1.42 1 1 0 001.42 0l4-4a1 1 0 000-1.42zM18.32 12.05a1 1 0 00-1.27.63l-4 12A1 1 0 0013.68 26 1.25 1.25 0 0014 26a1 1 0 00.95-.68l4-12A1 1 0 0018.32 12.05z"></path>
        </svg>
      </Cards>
      <Cards
        cardMinWidth={cardMinWidth}
        title={"SEO Marketing"}
        description={
          " Naša SEO strategija pomaže vam da budete vidljivi na internetu, povećavajući vašu prisutnost i rangiranje na pretraživačima."
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 101 101"
          id="graph"
        >
          <path d="M82.2 79.8h-61v-61c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v63.4c0 1.3 1.1 2.4 2.4 2.4h63.4c1.3 0 2.4-1.1 2.4-2.4 0-1.3-1.1-2.4-2.4-2.4z"></path>
          <path d="M44.5 70.5V35.9c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v34.7c0 1.3 1.1 2.4 2.4 2.4s2.4-1.1 2.4-2.5zM32.9 70.5V35.9c0-1.3-1.1-2.4-2.4-2.4-1.3 0-2.4 1.1-2.4 2.4v34.7c0 1.3 1.1 2.4 2.4 2.4 1.3-.1 2.4-1.1 2.4-2.5zM67.8 70.5V35.9c0-1.3-1.1-2.4-2.4-2.4-1.3 0-2.4 1.1-2.4 2.4v34.7c0 1.3 1.1 2.4 2.4 2.4 1.3-.1 2.4-1.1 2.4-2.5zM79.4 70.5V53.2c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v17.3c0 1.3 1.1 2.4 2.4 2.4s2.4-1 2.4-2.4zM56.1 70.5V53.2c0-1.3-1.1-2.4-2.4-2.4s-2.4 1.1-2.4 2.4v17.3c0 1.3 1.1 2.4 2.4 2.4s2.4-1 2.4-2.4z"></path>
        </svg>
      </Cards>
    </>
  );
};

export default AllServices;
