import Partners from "./Partners";
import AllServices from "./AllServices";
import React, { useState, useEffect } from "react";
import classes from "./Home.module.css";
import Button from "./Button";
import { Link } from "react-router-dom";
import ServiceCards from "./ServiceCards";
import "./ServiceCards.css";
import Loader from "./Loader";
import PortfolioHome from "./PortfolioHome";
import Contact from "./Contact";
import Helmet from "../helmet/helmet";
const Home = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    // Simulating some asynchronous operation, like fetching data
    const fetchData = async () => {
      // Simulate a delay of 2 seconds
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setIsLoading(false); // After 2 seconds, set isLoading to false
    };

    fetchData(); // Call the asynchronous function
  }, []); // Run this effect only once, on component mount

  return (
    <>
    <Helmet></Helmet>
    <main className={classes.home}>
      {isLoading && <Loader />}
      <section className={classes.homeBg}>
        <h4 className={classes["tp-section-subtitle"]}>Najbolja rešenja</h4>
        <h1 className={classes["tp-slider-title"]}>
          Web Dizajn i<br></br> Digitalni Marketing
        </h1>
        <p>
          Dizajniramo efikasne i moderne sajtove za Vaš biznis koji rade posao!
          <br></br>
          Dajte Vašem biznisu sajt baš onakav kakav zaslužuje.
        </p>
        <div className={classes.btnContainerHome}>
          <Button className={"tp-btn"}>
            <Link to={"/usluge"}>Portfolio</Link>
          </Button>
          <Button className={"tp-btn"}>
            <Link to={"/contact"}>Kontakt</Link>
          </Button>
        </div>
        <div className={classes.partners}>
          <Partners />
        </div>
        <div className={classes.serviceCardsContainer}>
          <ServiceCards
            title={"Naše usluge koje ti mogu biti od koristi!"}
            linkName={"NAŠE USLUGE"}
            icon={""}
            isGreen={true}
            href={"/usluge"}
          />
          <ServiceCards
            title={"Kontaktiraj nas i započni svoj projekat!"}
            linkName={"KONTAKT"}
            icon={""}
            isYellow={true}
            isGreen={false}
            href={"/contact"}
          />
          <ServiceCards
            title={"Pogledaj neke od naših skorašnjih projekata"}
            linkName={"NAŠI PROJEKTI"}
            icon={""}
            isBlue={true}
            href={"/portfolio"}
          />
        </div>
      </section>
      <section className={classes.services_container}>
        <h4
          className={
            classes["tp-section-subtitle"] + " " + classes["tp-yellow-color"]
          }
        >
          <span>Naše Usluge</span>
        </h4>
        <h2 className={classes["tp-section-title"]}>
          Usluge sa kojima ti možemo pomoći
        </h2>
        <div>
          <div className={classes.servicesCards}>
            <AllServices />
          </div>
        </div>
        <Button className={"tp-show-more"}>
          <Link to={"/usluge"}> DETALJNIJE</Link>
        </Button>
      </section>
      <PortfolioHome slice={true} />
      <Contact />
    </main></>
  );
};

export default Home;
