import React from "react";
import classes from "./Cards.module.css";
const Cards = ({ title, description, children, cardMinWidth }) => {
  return (
    <div
      className={
        classes.Cards + " " + (cardMinWidth ? classes.cardMinWidth : "")
      }
    >
      {children}
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default Cards;
