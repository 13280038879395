import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Root from "./Root";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Home from "./components/Home";
import Services from "./pages/Services";
import About from "./pages/About";
import Contact from "./pages/Contact";
import SingleProject from "./pages/SingleProject";
import Portfolio from "./pages/Portfolio";
import NotFound from "./pages/NotFound";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/onama", element: <About /> },
      { path: "/contact", element: <Contact /> },
      { path: "/usluge", element: <Services /> },
      {
        path: "/portfolio",
        element: <Portfolio />,
      },
      { path: "/portfolio/:name", element: <SingleProject /> },
    ],
    errorElement: <NotFound />,
  },
]); 
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RouterProvider router={router}>
    <App />
  </RouterProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
