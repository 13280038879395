import React from "react";

import partner_1 from "../images/partner_1.png";
import partner_2 from "../images/partner_2.png";
import partner_3 from "../images/partner_3.png";
import partner_4 from "../images/partner_4.png";
import classes from "./Partners.module.css";
const Partners = () => {
  return (
    <div className={classes.partners} data-aos="fade-up">
      <img src={partner_2} />
      <img src={partner_3} />
      <img src={partner_1} />
      <img src={partner_4} />
    </div>
  );
};

export default Partners;
