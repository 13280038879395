const works = [
  {
    id: "1",
    name: "LOUŽIVO :: VESTI",
    thumbnail: "louzivo",
    type: "Izrada Website",
  },
  {
    id: "2",
    name: "BOOMERANG :: RESTORAN",
    thumbnail: "boomerang",
    type: "Izrada Website",
  },
  { id: "13", name: "ROYAL PUPPY", thumbnail: "royal", type: "Logo Design" },
  {
    id: "8",
    name: "AMBULANTA",
    thumbnail: "ambulanta",
    type: "Instagram Content",
  },
  {
    id: "3",
    name: "ESTEGRADNJA :: BLOG",
    thumbnail: "estegradnja",
    type: "Izrada Website",
  },
  {
    id: "4",
    name: "OMLADINSKI :: VESTI",
    thumbnail: "omladinski",
    type: "Izrada Website",
  },
  {
    id: "5",
    name: "SPELTAJEVTIĆ :: PRODAVNICA",
    thumbnail: "speltajevtic",
    type: "Izrada Website",
  },
  { id: "6", name: "TERETANA ", thumbnail: "gym", type: "Izrada Website" },
  { id: "7", name: "GRADEKS", thumbnail: "gradeks", type: "Izrada Website" },
  {
    id: "9",
    name: "DIJAGNOSTIČKI CENTAR",
    thumbnail: "dijagnosticki",
    type: "Instagram Content",
  },
  {
    id: "10",
    name: "BOOMERANG",
    thumbnail: "boomerang",
    type: "Reklamni materijal",
  },
  { id: "11", name: "AURORA", thumbnail: "aurora", type: "Instagram Content" },
  {
    id: "12",
    name: "MASTER KLIMA",
    thumbnail: "klima",
    type: "Instagram Content",
  },
  { id: "14", name: "UNO", thumbnail: "uno", type: "Logo Design" },
];
export default works;
