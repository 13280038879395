import React from "react";
import "./NotFound.css";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <>
      <h1 className="fourtyfour">404</h1>
      <p className="error404_paragraph">Oops! Something is wrong.</p>
      <Link className="button" to={"/"}>
        <i className="icon-home"></i> Go back in initial page, is better.
      </Link>
    </>
  );
};

export default NotFound;
