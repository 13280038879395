import Button from "./Button";
import { Link } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import classes from "./Home.module.css";
import { useState } from "react";
import boomerang from "../images/boomerang/boomerang.png";
import gradeks from "../images/logo-red.png";
import omladinski from "../images/omladinski_thumbnail.png";
import estegradnja from "../images/estegradnja_thumbnail.png";
import kapetanija from "../images/kapetanija.png";
import louzivo from "../images/louzivo.png";
import speltajevtic from "../images/speltajevtic_thumbnail.png";
import gym from "../images/f7.png";
import ambulanta from "../images/ambulanta/thumbnail-ambulanta.png";
import dijagnosticki from "../images/dijagnosticki centar/centar_thumbnail.png";
import aurora from "../images/aurora/Frame 6.png";
import royal from "../images/royal puppy/Frame 9.png";
import uno from "../images/uno-thumbnail.png";
import klima from "../images/master klima/3.jpg";
import portfolioClasses from "./PortfolioHome.module.css";
import data from "../data";
import Spinner from "./Spinner";
//firebase
import {
  get,
  ref,
  orderByChild,
  equalTo,
  query,
  limit,
  limitToFirst,
} from "firebase/database";
import database from "../firebase";
import Loader from "./Loader";
import Aos from "aos";
import "aos/dist/aos.css"; // Import AOS CSS

const PortfolioHome = ({ slice }) => {
  const imagePaths = {
    royal: royal,
    gradeks: gradeks,
    gym: gym,
    omladinski: omladinski,
    louzivo: louzivo,
    ambulanta: ambulanta,
    klima: klima,
    uno: uno,
    speltajevtic: speltajevtic,
    dijagnosticki: dijagnosticki,
    aurora: aurora,
    boomerang: boomerang,
    estegradnja: estegradnja,
    kapetanija:kapetanija
  };
  const [works, setWorks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState([]);
  const [width, setWidth] = useState(0);
  const carouselCategory = useRef(null);
  const [activeCategory, setActiveCategory] = useState("All");

  useEffect(() => {
    Aos.init();
  }, []);

  const [isDraggable, setIsDraggable] = useState(true);
  const fetchDataProjects = (category) => {
    try {
      setIsLoading(true);
      // Construct query to filter projects based on type
      const projectsRef = ref(database, "portfolio");
      let filteredProjectsQuery;
      if (category !== "All") {
        filteredProjectsQuery = query(
          projectsRef,
          orderByChild("type"),
          equalTo(category)
        );
      } else {
        if (slice) {
          filteredProjectsQuery = query(projectsRef, limitToFirst(5));
        } else {
          filteredProjectsQuery = query(projectsRef);
        }
      }

      get(filteredProjectsQuery).then((snapshot) => {
        if (snapshot.exists()) {
          const projectArray = Object.entries(snapshot.val()).map(
            ([id, data]) => ({
              id,
              ...data,
            })
          );
          setWorks(projectArray);
        } else {
          setWorks(false);
        }
        setIsLoading(false);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataProjects("All");
    const categories = data.map((item) => item.type);
    setCategory([...new Set(categories)]);
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    fetchDataProjects("All");
    const categories = data.map((item) => item.type);
    setCategory([...new Set(categories)]);
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    const updateWidth = () => {
      if (carouselCategory.current) {
        setWidth(
          carouselCategory.current.scrollWidth -
            carouselCategory.current.offsetWidth
        );
        // Update the draggability based on window width
        setIsDraggable(window.innerWidth <= 968);
      }
    };

    // Attach the load event listener
    window.addEventListener("load", updateWidth);

    return () => {
      // Clean up the event listener on component unmount
      window.removeEventListener("load", updateWidth);
    };
  }, []);

  const filterProjects = (category) => {
    setActiveCategory(category);
    fetchDataProjects(category);
  };

  return (
    <section
      className={classes.portfolio_home}
      data-aos="fade-up"
      data-aos-delay="600"
    >
      <h4
        className={
          classes["tp-section-subtitle"] + " " + classes["tp-yellow-color"]
        }
      >
        Portfolio
      </h4>
      <h2
        className={classes["tp-section-title"]}
        style={{ textAlign: "center" }}
      >
        Naši projekti
      </h2>
      {!slice && (
        <motion.div
          className={portfolioClasses.carouselCategory}
          ref={carouselCategory}
        >
          <motion.div
            className={portfolioClasses.category}
            {...(isDraggable && {
              drag: "x",
              dragConstraints: { left: -width, right: 0 },
            })} // Conditionally apply drag properties
          >
            <motion.button
              className={
                portfolioClasses.categoryBtn +
                " " +
                (activeCategory === "All" ? portfolioClasses.active : "")
              }
              onClick={() => filterProjects("All")}
            >
              Svi Projekti
            </motion.button>
            {category &&
              category.map((category, index) => {
                return (
                  <button
                    className={
                      portfolioClasses.categoryBtn +
                      " " +
                      (activeCategory === category
                        ? portfolioClasses.active
                        : "")
                    }
                    onClick={() => filterProjects(category)}
                    key={index}
                  >
                    {category}
                  </button>
                );
              })}
          </motion.div>
        </motion.div>
      )}
      <motion.div
        layout="position"
        className={slice ? classes.works : classes.grid}
      >
        {isLoading && <Spinner />}
        {!works && <h2 className={portfolioClasses.noproducts}>Trenutno nema projekata za izabranu kategoriju.</h2>}
        {works &&
          works.map((project) => {
            return (
              <motion.article
                layout="position"
                className={portfolioClasses.worksLink}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.05 * project.id }}
                key={project.id}
                href={`/portfolio/${project.thumbnail}`}
              >
                <Link to={`/portfolio/${project.thumbnail}`}>
                  <img
                    src={imagePaths[project.thumbnail]}
                    alt={project.thumbnail}
                  />
                </Link>
              </motion.article>
            );
          })}
      </motion.div>
      {slice && (
        <Button className={"tp-show-more"}>
          <Link to={"/portfolio"}>PORTFOLIO</Link>
        </Button>
      )}
    </section>
  );
};

export default PortfolioHome;
