import React from "react";
import classes from "./Breadcrumb.module.css";
const Breadcrumb = ({ title, breadcrumb }) => {
  return (
    <div
      className={classes.breadcrumb}
      data-aos-duration="500"
      data-aos-delay="200"
      data-aos="fade-up"
    >
      <div className={classes.breadcrumbContainer}>
        <div className={classes.breadcrumbRow}>
          <h2 className={classes.breadcrumbTitle}>{title}</h2>
          <h4 className={classes.breadcrumbLinks}>{breadcrumb}</h4>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumb;
